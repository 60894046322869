/**
    Reference: app-unavailability-advisor
*/
table.calendar-container {
    min-width: 230px !important;
    max-width: 300px !important;
    width: 100% !important;
    /* border-collapse: separate; */
    /* font-size: 12px !important; */
    margin-left: 20px;
    /* border:1px solid #000; */
    box-shadow: 0px 0px 5px 0.2px rgba(0, 0, 0, 0.2);
    padding: 0px 8px;
}

table.calendar-container td,
table.calendar-container th {
    /* border: 1px solid #ddd; */
    text-align: center;
}

table.calendar-container > thead > tr > th {
    padding: 5px;
}

table.calendar-container > tbody > tr > td {
    padding: 5px;
    text-align: center;
}

table.calendar-container > tbody > tr > td span {
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    padding-top:3px;
}

.all-day-calendar span {
    background-color: #0276b3;
    font-weight: 600;
    color: #fff;

}
.partial-day-calendar span {
    background-color: #d7dfed;
    font-weight: 600;
    border-radius: 50%;
    color: #1c243c;
}

.btn-calendar {
    border: 0 none;
    outline: none;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    background-color: transparent;
    cursor: pointer;
    outline: none !important;
}

.btn-calendar:disabled {
    cursor: no-drop;
}

.container-header-calendar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
}


