.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: #0276b3;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #002f6c;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-radio-outer-circle {
  /*border-color: rgba(0, 0, 0, .54)*/
  border: 2.25px solid #002f6c;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #002f6c;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #002f6c;
}

.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.26);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #002f6c;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #002f6c;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.26);
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-inner-circle {
  background-color: #f44336;
}

.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.26);
}

.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}

/* slide toogle */
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #a6d7b4;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #46d090;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06);
}

.mat-slide-toggle .mat-ripple-element {
  background-color: rgba(233, 30, 99, 0.12);
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #89dd78;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.5);
}

.mat-slide-toggle.mat-primary:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06);
}

.mat-slide-toggle.mat-primary .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.12);
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f44336;
}

.mat-slide-toggle.mat-warn.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.5);
}

.mat-slide-toggle.mat-warn:not(.mat-checked) .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.06);
}

.mat-slide-toggle.mat-warn .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.12);
}

.mat-disabled .mat-slide-toggle-thumb {
  background-color: #bdbdbd;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #bdbdbd;
}

.mat-disabled .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-slide-toggle-thumb {
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
  position: relative;
  width: 40px;
  height: 22px;
  flex-shrink: 0;
  border-radius: 10px;
}

.mat-slide-toggle-thumb {
  height: 18px;
  width: 18px;
}

.mat-slide-toggle-thumb-container {
  position: absolute;
  z-index: 1;
  width: 17px;
  height: 17px;
  top: 1px;
  left: 2px;
  transform: translate3d(0, 0, 0);
  transition: all 80ms linear;
  transition-property: transform;
  cursor: pointer;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(22px, 0, 0);
}

/** Checkbox */
.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: #475776;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #475776;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

@media screen and (-ms-high-contrast: black-on-white) {
  .mat-checkbox-checkmark-path {
    stroke: #000 !important;
  }
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: #002f6c;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #0276b3;
}

.mat-checkbox-checked.mat-warn .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: #b0b0b0;
}

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-disabled {
    opacity: 0.5;
  }
}

@media screen and (-ms-high-contrast: active) {
  .mat-checkbox-background {
    background: 0 0;
  }
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-primary .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.26);
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(63, 81, 181, 0.26);
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-warn .mat-checkbox-ripple .mat-ripple-element {
  background-color: rgba(244, 67, 54, 0.26);
}

div.mat-form-field-infix {
  width: 100%;
}

.mat-option {
  font-family: 'FordAntenna', 'Roboto', 'Helvetica Neue', 'sans-serif';
}

// aqui precisamos aumentar a especificidade desse seletor para sobreescrever o padrão do material
mat-dialog-container.mat-dialog-container {
  border-radius: 15px;
  font-family: 'FordAntenna', 'Roboto', 'Helvetica Neue', sans-serif;
}

/// aqui fica a ressalva de termos de usar um seletor de elementos e, ainda pior, !important
/// o componente de espansão do material adiona o height como inline style quando o componente é aberto ou fechado
/// por isso essa medida drástica
mat-expansion-panel-header {
  height: auto !important;
}

.mat-expansion-panel {
  box-shadow: none;
  color: inherit;
}

.mat-expansion-panel-header {
  padding: 0 24px;
  font-family: 'FordAntenna', Roboto, 'Helvetica Neue', sans-serif;
}

.mat-expansion-panel-content {
  font-family: 'FordAntenna', Roboto, 'Helvetica Neue', sans-serif;
}

/// sobreescrevendo o background usado quando há o hover (mais uma vez, foi necessário usar um seletor com alta especificidade porque o default
/// já é bem específico)
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: inherit;
}

/// aqui foi necessário aumentar a especificidade do seletor para sobreescrever o padrão do material
mat-expansion-panel-header span.mat-content {
  flex: 0 1 auto;
}

/// sobreescrevendo a cor do texto usada no expansion panel
.mat-expansion-panel-header-title {
  color: inherit;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #002f6c;
}

.mat-tab-label-active {
  color: #102b4e;
  opacity: 1 !important;
}

.mat-tab-label {
  font-weight: 600;
  font-family: 'Ford Antenna';
}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard{
  border: none;
}

mat-button-toggle{
  width: 100px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 12px;
  font-family: 'Ford Antenna';
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 35px;
}
