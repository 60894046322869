/* ------- ELEMENTOS ------- */

/* reset */
body, h1, h2, h4, h5, h6, p, blockquote, pre, dl, dd, ol, menu, form, fieldset, legend, figure, section, summary {
  margin: 0;
  margin-right: 14px;
  margin-left: 10px;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

button, fieldset, iframe, img {
  border: 0;
}

html {
  font: 100% FordAntenna, Arial, Helvetica, sans-serif;
  color: #000;
}

body {
  font-size: 10pt;
}

li {
  list-style: none;
}

/* fontes */
@font-face {
  font-family: 'Candara';
  src: url('../fonts/candara.eot');
  src: url('../fonts/candara.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Candara.ttf') format('truetype'),
  url('../fonts/candara.svg#CandaraRegular') format('svg');
}

/* Fontes Ford */

/* FordAntenna (LIGHT) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/antenna/Ford-Antenna-Light.ttf?#iefix') format('truetype'),
  url('../fonts/antenna/Ford-Antenna-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/antenna/Ford-Antenna-Light.woff') format('woff'),
  url('../fonts/antenna/Ford-Antenna-Light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
}

.FordAntennaLight {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}

/* FordAntenna (LIGHT ITALIC) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/antenna/Ford-Antenna-Light-Italic.ttf?#iefix') format('truetype'),
  url('../fonts/antenna/Ford-Antenna-Light-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/antenna/Ford-Antenna-Light-Italic.woff') format('woff'),
  url('../fonts/antenna/Ford-Antenna-Light-Italic.svg') format('svg');
  font-weight: 300;
  font-style: italic;
}

.FordAntennaLightItalic {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-style: italic;
}

/* FordAntenna (REGULAR) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-4.svg') format('svg');
  font-weight: 400;
  font-style: normal;
}

.FordAntenna {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* FordAntenna (REGULAR ITALIC) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Regular-Italic/b293f486-517d-490f-9d18-75a4f46675a6-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Regular-Italic/b293f486-517d-490f-9d18-75a4f46675a6-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Regular-Italic/b293f486-517d-490f-9d18-75a4f46675a6-3.woff ') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Regular-Italic/b293f486-517d-490f-9d18-75a4f46675a6-4.svg') format('svg');
  font-weight: 400;
  font-style: italic;
}

.FordAntennaItalic {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-style: italic;
}

/* FordAntenna (MEDIUM) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-1.ttf?#iefix') format("truetype"),
  url('../fonts/Ford/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-2.eot?#iefix') format("embedded-opentype"),
  url('../fonts/Ford/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-3.woff') format("woff"),
  url('../fonts/Ford/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-4.svg') format("svg");
  font-weight: 500;
  font-style: normal;
}

.FordAntennaMedium {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 500;
}

/* FordAntenna (MEDIUM ITALIC) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Medium-Italic/a1bff13e-d111-4d77-a245-a2a2ae9d56a6-1.ttf?#iefix') format("truetype"),
  url('../fonts/Ford/Ford-Antenna-Medium-Italic/a1bff13e-d111-4d77-a245-a2a2ae9d56a6-2.eot?#iefix') format("embedded-opentype"),
  url('../fonts/Ford/Ford-Antenna-Medium-Italic/a1bff13e-d111-4d77-a245-a2a2ae9d56a6-3.woff') format("woff"),
  url('../fonts/Ford/Ford-Antenna-Medium-Italic/a1bff13e-d111-4d77-a245-a2a2ae9d56a6-4.svg') format("svg");
  font-weight: 500;
  font-style: italic;
}

.FordAntennaMediumItalic {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: italic;
}

/* FordAntenna (SEMI BOLD) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Semibold/Ford-Antenna-Semibold.ttf?#iefix') format("truetype"),
  url('../fonts/Ford/Ford-Antenna-Semibold/Ford-Antenna-Semibold.eot?#iefix') format("embedded-opentype"),
  url('../fonts/Ford/Ford-Antenna-Semibold/Ford-Antenna-Semibold.woff') format("woff"),
  url('../fonts/Ford/Ford-Antenna-Semibold/Ford-Antenna-Semibold.svg') format("svg");
  font-weight: 600;
  font-style: normal;
}

.FordAntennaMedium {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

/* FordAntenna (SEMI BOLD ITALIC) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Semibold-Italic/Ford-Antenna-Semibold-Italic.ttf?#iefix') format("truetype"),
  url('../fonts/Ford/Ford-Antenna-Semibold-Italic/Ford-Antenna-Semibold-Italic.eot?#iefix') format("embedded-opentype"),
  url('../fonts/Ford/Ford-Antenna-Semibold-Italic/Ford-Antenna-Semibold-Italic.woff') format("woff"),
  url('../fonts/Ford/Ford-Antenna-Semibold-Italic/Ford-Antenna-Semibold-Italic.svg') format("svg");
  font-weight: 600;
  font-style: italic;
}

.FordAntennaMediumItalic {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* FordAntenna (BOLD) */
@font-face {
  font-family: "FordAntenna";
  src: url('../fonts/Ford/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-4.svg') format('svg');
  font-weight: 700;
  font-style: normal;
}

.FordAntennaBold {
  font-family: "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: normal;
}

/* FordAntenna (BOLD ITALIC) */
@font-face {
  font-family: "FordAntennaBoldItalic";
  src: url('../fonts/Ford/Ford-Antenna-Bold-Italic/25beecd8-cfd9-4863-bfde-4a7e8ab4bed8-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Bold-Italic/25beecd8-cfd9-4863-bfde-4a7e8ab4bed8-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Bold-Italic/25beecd8-cfd9-4863-bfde-4a7e8ab4bed8-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Bold-Italic/25beecd8-cfd9-4863-bfde-4a7e8ab4bed8-4.svg') format('svg');
  font-weight: 700;
  font-style: italic;
}

.FordAntennaBoldItalic {
  font-family: "FordAntennaBoldItalic", "FordAntenna", Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "FordAntennaCond";
  src: url('../fonts/Ford/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-4.svg') format('svg');
}

.FordAntennaCond {
  font-family: "FordAntennaCond", "FordAntenna", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "FordAntennaCondItalic";
  src: url('../fonts/Ford/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-4.svg') format('svg');
}

.FordAntennaCondItalic {
  font-family: "FordAntennaCondItalic", "FordAntenna", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "FordAntennaCondBold";
  src: url('../fonts/Ford/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-4.svg') format('svg');
}

.FordAntennaCondBold {
  font-family: "FordAntennaCondBold", "FordAntenna", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "FordAntennaCondBoldItalic";
  src: url('../fonts/Ford/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-4.svg') format('svg');
}

.FordAntennaCondBoldItalic {
  font-family: "FordAntennaCondBoldItalic", "FordAntenna", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "FordAntennaCondMedium";
  src: url('../fonts/Ford/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-4.svg') format('svg');
}

.FordAntennaCondMedium {
  font-family: "FordAntennaCondMedium", "FordAntenna", Arial, Helvetica, sans-serif;
}

@font-face {
  font-family: "FordAntennaCondMediumItalic";
  src: url('../fonts/Ford/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-1.ttf?#iefix') format('truetype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-2.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Ford/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-3.woff') format('woff'),
  url('../fonts/Ford/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-4.svg') format('svg');
}

.FordAntennaCondMediumItalic {
  font-family: "FordAntennaCondMediumItalic", "FordAntenna", Arial, Helvetica, sans-serif;
}
