.btn-general-color-ford {
  background: linear-gradient(to bottom, #102b4e 5%, #102b4e 100%) !important;
  background-color: #102b4e !important;
}
.btn-general-color-ford:disabled {
  cursor: no-drop !important;
  background-color: #d4dceb;
  color: #656b70;
}

.btn-general-cancel-ford {
  border: 1px solid #d7dfed !important;
}
/*----------------------------------*/
.custom-btn-ford {
  height: 60px;
  min-width: 110px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "FordAntenna";
  font-size: 18px !important;
  font-weight: 500;
  padding: 10px 20px;
  outline: none !important;
}
.ford-btn-primary {
  background-color: #102b4e !important;
  color: #ffffff !important;
}
.ford-btn-primary:hover {
  background-color: #102b4e;
}
.ford-btn-secondary {
  background-color: #e6eaf0 !important;
  color: #102b4e !important;
}
.ford-btn-tertiary {
  background-color: #fff !important;
  color: #596783 !important;
  border: 1px solid #d7dfed !important;
}
.ford-btn-primary:disabled,
.ford-btn-secondary:disabled {
  cursor: no-drop !important;
  background-color: #d4dceb !important;
  color: #656b70 !important;
  border:0px !important;
}
.custom-btn-ford:disabled {
  cursor: no-drop !important;
  background-color: #d4dceb !important;
  color: #656b70 !important;
}
